import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ROUTES } from './helper/constants';

import PaymentFormContainer from './container/PaymentFormContainer';

import './i18n';

const App = (props) => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path={ROUTES.HOME}
					exact={true}
					element={<PaymentFormContainer />}
				/>
				<Route
					path={ROUTES.PAYMENT}
					exact={true}
					element={<PaymentFormContainer />}
				/>
			</Routes>
		</BrowserRouter>	
	);
}

export default App;
