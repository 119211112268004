const {NODE_ENV, REACT_APP_DEV_API, REACT_APP_PROD_API} = process.env;
const API_ENDPOINT = NODE_ENV === 'development' ? REACT_APP_DEV_API : REACT_APP_PROD_API;

export const stripePaymentMethodHandler = async (data, cb) => {
	const { amount, result, currency, description } = data;
	return await stripePayment({
		payment_method_id: result.paymentMethod.id,
		email: result.paymentMethod.billing_details.email,
		amount: amount,
		currency: currency,
		description: description
	});
}

// place backend API call for payment
const stripePayment = async data => {
	const res = await fetch(`${API_ENDPOINT}/pay`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	})
	return await res.json();
}