import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSearchParams, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import successIcon from '../../helper/images/success-icon.svg';
import companyLogo from '../../helper/images/studency-pie-centered-pos-rgb.png';
import PaymentForm from '../../component/PaymentForm';

import './payment-form-container.css';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const {NODE_ENV, REACT_APP_STRIPE_DEV_API_KEY, REACT_APP_STRIPE_PROD_API_KEY} = process.env;
const stripePromise = loadStripe(NODE_ENV === 'development' ? REACT_APP_STRIPE_DEV_API_KEY : REACT_APP_STRIPE_PROD_API_KEY);

const PaymentFormContainer = ({t, ...props}) => {
	const [paymentCompleted, setPaymentCompleted] = useState(false);
	const { search } = useLocation();
	const [searchParams] = useSearchParams(search);
	const email = searchParams.get("email");
	const currency = {
		sign: '€',
		value: 'EUR'
	};
	const description = "Exam Subscription Fees";
	const amount = 40.00;

	return (
		<div className="container">
			<div className="py-4 text-center">
				<img
					className='company-logo'
					src={companyLogo}
					alt={"company-logo"}
				/>
			</div>

			<div className="row s-box">
				{
					paymentCompleted
						?
						<div className="success-msg">
							<img
								src={successIcon}
								alt='success-icon'
								title='success'
							/>
							<div className="title">{t('payment_successful')}</div>
						</div>
						:
						<>
							<div className="col-md-12 order-md-1">
								<Elements stripe={stripePromise}>
									<PaymentForm
										amount={amount}
										currency={currency}
										description={description}
										email={email}
										setPaymentCompleted={setPaymentCompleted}
									/>
								</Elements>
							</div>
						</>
				}
			</div>

		</div>
	);
}

export default withTranslation()(PaymentFormContainer);
