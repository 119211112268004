import axios from 'axios';

const portalId = process.env.REACT_APP_PORTAL_ID;
const hubspotAPIKey = process.env.REACT_APP_HUBSPOT_API_KEY;
const paymentFormId = process.env.REACT_APP_PAYMEMT_FORM_ID;
const registrationFormId = process.env.REACT_APP_REGISTRATION_FORM_ID;

export const postHubspotRegistrationForm = async ({firstname, lastname, email, phone, address, city, state, country, link }) => {
	const config = { // important!
		headers: {
			'Content-Type': 'application/json',
		}
	};

	const data = {
		portalId,
		formGuid: registrationFormId,
		fields: [
			{
				name: 'firstname',
				value: firstname,
			},
			{
				name: 'lastname',
				value: lastname,
			},
			{
				name: 'email',
				value: email,
			},
			{
				name: 'phone',
				value: phone,
			},
			{
				name: 'address',
				value: address,
			},
			{
				name: 'city',
				value: city,
			},
			{
				name: 'state',
				value: state,
			},
			{
				name: 'country',
				value: country,
			},
			{
				name: 'link',
				value: link,
			},
		],
	};

	return await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${registrationFormId}?hapikey=${hubspotAPIKey}`, data, config);
}

export const postHubspotPaymentForm = async ({ payment, email }) => {
	const config = { // important!
		headers: {
			'Content-Type': 'application/json',
		}
	};

	const data = {
		portalId,
		formGuid: paymentFormId,
		fields: [
			{
				name: 'payment',
				value: payment,
			},
			{
				name: 'email',
				value: email,
			}
		],
	};

	return await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${paymentFormId}?hapikey=${hubspotAPIKey}`, data, config);
}