import React, { useState } from 'react';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement
} from '@stripe/react-stripe-js';
import { withTranslation } from 'react-i18next';

import { stripePaymentMethodHandler } from '../../services/script';
import { postHubspotPaymentForm } from '../../services/hubspot.services';

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			lineHeight: "27px",
			color: "#212529",
			fontSize: "1.1rem",
			"::placeholder": {
				color: "#aab7c4",
			},
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a",
		},
	},
};

const PaymentForm = ({t, amount, email, currency, description, setPaymentCompleted}) => {
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const stripe = useStripe();
	const elements = useElements({locale: 'fr'});

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		try {
			if (!stripe || !elements || !email) {
				return;
			}

			setLoading(true);
			setErrorMsg('');

			const paymentMethodObj = {
				type: 'card',
				card: elements.getElement(CardNumberElement),
				billing_details: {
					email
				},
			};
			const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);

			const response = await stripePaymentMethodHandler({ result: paymentMethodResult, amount: amount, currency: currency.value, description: description });
			if (response.error) {
				setErrorMsg(typeof response.error === 'string' ? response.error : response.error.message);
			} else {
				await postHubspotPaymentForm({payment: amount, email: email});
				setPaymentCompleted(response.success ? true : false);
			}
			
		} catch (error) {
			console.log(error, error.message);
			setErrorMsg(t('an_issue_processing_payment'));
		} finally {
			setLoading(false);
		}
	};

	return (
		<React.Fragment>
			<h4 className="d-flex justify-content-between align-items-center mb-3">
				<span className="text-muted">{t('subscription_fees_for_the_exam')}</span>
			</h4>
			<form onSubmit={handleSubmit}>

				<div className="row">
					<div className="col-md-12 mb-3">
						<label htmlFor="cc-email">{t('email_address')}</label>
						<input
							id="cc-email"
							type="text"
							className="form-control"
							value={email}
							disabled
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12 mb-3">
						<label htmlFor="cc-number">{t('card_number')}</label>
						<CardNumberElement
							id="cc-number"
							className="form-control"
							options={CARD_ELEMENT_OPTIONS}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6 mb-3">
						<label htmlFor="expiry">{t('expiration_date')}</label>
						<CardExpiryElement
							id="expiry"
							className="form-control"
							options={CARD_ELEMENT_OPTIONS}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="cvc">{t('cvc')}</label>
						<CardCvcElement
							id="cvc"
							className="form-control"
							options={CARD_ELEMENT_OPTIONS}
						/>
					</div>
				</div>

				<hr className="mb-4" />
				<button className="btn btn-dark w-100" type="submit" disabled={loading}>
					{
						loading ?
						<div className="spinner-border spinner-border-sm text-light" role="status"></div> :
						`${t('pay')} ${currency.sign}${amount}`
					}
				</button>
				{errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
			</form>
		</React.Fragment>
	);
}

export default withTranslation()(PaymentForm);
